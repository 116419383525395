<template>
    <layout class="box">
        <div class="container mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/special-ma/index/'+$route.params.id }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/special-ma/org-list/'+$route.params.id }">相关机构</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>机构详情</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <a-spin :spinning="loading" tip="Loading...">
            <div class="content">
                <div class="" v-if="dataList">
                    <div class="flex x-center y-center content-box">
                        <img class="content-logo" :src="dataList.img | urlFilter(250,'avatar') || require('@/assets/images/committee-logo.jpg')" alt="">
                        <span class="ml10 fs18 strong row-3 color000">{{ dataList.title }}</span>
                    </div>
                    <div class="mt10" style="text-align: initial;" v-html="dataList.indroduce"></div>
                </div>
                <a-empty style="margin-top:100px;" v-if="!dataList && !loading" />
                
            </div>
        </a-spin>
    </layout>
</template>

<script>
    import layout from '@/components/layout/index-ma';
    import storage from 'store'
    export default {
        name: "specialMaOrgDetail",
        components : {
            layout
        },
        data(){
            return {
                id:this.$route.query.id,
                dataList : {},
                loading : true,
            }
        },
        created() {
          this.getOrgDetail();
        },
        methods :{
            getOrgDetail(){
                this.loading = true
                this.request.post('TopicOrgDetail', {org_id: this.id,topic_id:this.$route.params.id}).then(res => {
                    this.dataList = res.data || {}

                    this.loading = false
                })
            },
            
        }
    }
</script>

<style scoped lang="less">
    .content{
        width: 1240px;
        min-height: 798px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin: 0 auto;
        align-content: flex-start;
        &-box{
            padding-bottom: 10px;
            border-bottom: 1px dashed #ccc;
        }
        &-logo{
            width: 150px;
            height: 100px;
            object-fit: contain;

        }
    }
    @media screen and (max-width:768px){
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            
        }
    }

</style>